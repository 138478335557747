export const getContrastingColor = (color) => {
    color = color.replace('#', '');
    const red = parseInt(color.substr(0, 2), 16);
    const green = parseInt(color.substr(2, 2), 16);
    const blue = parseInt(color.substr(4, 2), 16);

    const brightness = (red * 299 + green * 587 + blue * 114) / 1000;
    if (brightness > 128) {
        return '#000000'; // Return black for bright colors
    } else {
        return '#FFFFFF'; // Return white for dark colors
    }
}