import styled from "styled-components";

export const TestCaseWrap = styled.div`

.refresh_button {
  width: 150px;
  background: var(--primary-color);
  border: none;
  color: white;
}
.project_select {
  width: 200px;
}
.text_case_actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.spinner {
  width: 1.125rem;
  height: 1.125rem;
}
.status {
  padding-top: 10px;
  font-weight: 500;
}

@media (max-width: 768px) {
  .container {
    width: 100% !important;
    margin: 0;
    margin-right: 0;
    padding: 0;
  }
}
`