import { masterDataSet } from "../../MasterData/layoutRules"
import { ActionTypes } from "./actions"

const checkLogin = localStorage.getItem("isLoggedIn") ?? false
const authToken = localStorage.getItem("token") ?? ""
const user = localStorage.getItem("user") ?? ""

const intialState = {
	isLoggedIn: checkLogin,
	authToken: authToken,
	user: user,
	showNote: false,
	sessionTimeout: false,
	layout: masterDataSet
}

export const AuthReducer = (state = intialState, { type, payload }) => {
	switch (type) {
		case ActionTypes.LOGIN:
			return {
				...state,
				isLoggedIn: payload.isLoggedIn,
				authToken: payload.authToken,
				user: payload.user,
			}
		case ActionTypes.LOGOUT:
			return {
				...state,
				isLoggedIn: payload.isLoggedIn,
				authToken: payload.authToken,
				user: payload.user,
			}
		case ActionTypes.SHOW_NOTE:
			return {
				...state,
				showNote: payload,
			}
		case ActionTypes.SET_SESSION_TIMEOUT:
			return {
				...state,
				sessionTimeout: payload,
			}
		default:
			return state
	}
}
