import { BrowserRouter as Router } from 'react-router-dom';
import UIRoutes from './Routes';
import { GlobalStyles } from "./assets/styles/js/GlobalStyles"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle.min.js"
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ThemeProvider, createTheme } from '@mui/material';

export const client = new QueryClient()

function App() {
  const theme = createTheme()

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={client}>
        <GlobalStyles />
        <Router>
          <UIRoutes />
        </Router>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
