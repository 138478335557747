import React from 'react';
import NotFoundWrap from './style.js';
import pageNotFoundImg from "../../../assets/images/404.svg"
import { Container } from 'react-bootstrap';

const PageNotFound = () => {
	return (
		<NotFoundWrap>
			<Container className='text-center'>
				<img className='image' src={pageNotFoundImg} alt="404" />
			</Container>
		</NotFoundWrap>
	)
}

export default PageNotFound;