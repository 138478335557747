import styled from "styled-components";

export const FormWrap = styled.div`
.eye {
    position: absolute;
    top: -35px;
    right: 12px;
    color: var(--primary-color);
}
.loginSubmitBtn {
    background-color: var(--primary-color);
    color: white;
    font: normal normal medium 24px/35px 'Poppins',sans-serif;
    letter-spacing: 0px;
    border: none;
}
.signup p{
    color: #272727;  
    margin-bottom: 0px;
}
p.account{
    margin-bottom: 0px;
}
.forgotLink{
    float: right;
    margin-top: -20px;
    font: normal normal medium 20px/30px Poppins;
    letter-spacing: 0px;
}
.logoimg{
    width: 50%;
    margin-right: 25%;
    margin-left: 25%;
    padding-top: 2%;
}
 .card{
    border: none;
 }
 .card-body {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
.signup:hover{
    color: black;
}

`