import styled from "styled-components";

export const TestCaseCreateWrap = styled.div`

.submit_button {
    width: 100%;
    margin-top: 1rem;
}
.col-md-6, .col-md-12 {
    margin-bottom: 1rem;
}
.invalid-feedback {
    display: block;
    font-size: 0.8rem;
    color: var(--primary-color);
}

`
