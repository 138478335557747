import React, { useEffect, useState } from 'react';
import Form from "react-bootstrap/Form";
import { Row, Col, Card, Button, Spinner } from 'react-bootstrap';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { validateInput } from '../../../Utilities/validator';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { WysiwygEditor } from '../RuWysiwygEditor';
import { FormWrap } from './style';


export const Forms = ({
    title,
    formArr,
    submitBtn,
    onSubmit,
    formlinks,
    loading,
    value,
    cancelBtn,
    onCancel,
    classes,
    submitted,
    changeInForm,
    setChangeInForm,
}) => {
    const prepareForm = (formArr) => {
        return formArr.reduce((r, v) => ({ ...r, [v.name]: "" }), {});
    };

    /*************************** react state  intiaalize *******************/

    const [formAr, setFormAr] = useState(formArr);
    const [valid, setValid] = useState(false);
    const intialForm = prepareForm(formArr);
    const [form, setform] = useState(intialForm);
    const [isSubmit, setSubmit] = useState(false);
    const [isCancel, setCancel] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const [editorChanged, setEditorChanged] = useState(false);

    /************** Functions ******************/
    const onChangeHandler = (e) => {
        setChangeInForm(true);
        setform((p) => ({ ...p, [e.target.name]: e.target.value }));
        formAr.forEach((item) => {
            let index = formAr.findIndex((item) => item.name === e.target.name);
            if (index !== undefined) formAr[index].value = e.target.value;
        });
        setCancel(false);
        setSubmit(false);
        setValid(false);
    };

    const onCheckboxHandler = (e) => {
        formAr.forEach((value) => {
            if (value.name === e.target.name) {
                if (value.value) {
                    value.value = false;
                    setform((p) => ({ ...p, [e.target.name]: false }));
                } else {
                    value.value = true;
                    setform((p) => ({ ...p, [e.target.name]: true }));
                }
            }
        });
    };

    const onSubmitHandle = () => {
        setSubmit(true);
        var keys = Object.values(form);
        keys.forEach((item, index) => {
            formAr.forEach((item1, index1) => {
                if (index === index1) {
                    let result = validateInput(item1.validators, item);
                    formAr[index1].error = result.error;
                    formAr[index1].errormsg = result.message;
                    setFormAr(formAr);
                    if (item1.match) {
                        if (form[item1.match] !== item) {
                            formAr[index].error = true;
                            formAr[index].errormsg = "Enter same password";
                        }
                    }
                    if (formAr.find((item) => item.error === true)) setValid(false);
                    else setValid(true);
                }
            });
        });
    };

    const onCancelHandle = (e) => {
        setCancel(true);
        onCancel(changeInForm);
    };

    useEffect(() => {
        setFormAr(formArr);
        formArr.forEach((item) => {
            setform((p) => ({ ...p, [item.name]: item.value }));
        });
    }, [value]);

    useEffect(() => {
        if (formAr.find((item) => item.error == true)) setValid(false);
    }, [form]);

    useEffect(() => {
        if (valid) {
            onSubmit(form, valid);
            setEditorChanged(true);
        }
    }, [valid]);

    useEffect(() => {
        formAr.forEach((item) => {
            item.value = "";
        });
        setFormAr(formAr);
        setform(formArr.reduce((r, v) => ({ ...r, [v.name]: "" }), {}));
        setEditorChanged(false);
    }, [submitted]);

    const toggleShowPassword = () => {
        return setShowPassword((prev) => !prev);
    };

    let oneTime = true;

    return (
        <FormWrap>
            <Card className="px-2">
                <Card.Body className={"loginformbody formBody " + classes}>
                    <Form
                        autoComplete="off"
                        onSubmit={(e) => {
                            onSubmitHandle();
                            e.preventDefault();
                        }}
                    >
                        {title != "" ? (
                            <h5 className="formScreenUser mb-0 pb-3">{title}</h5>
                        ) : (
                            <></>
                        )}
                        <Row className="mb-0">
                            {formAr.map(
                                (
                                    {
                                        label,
                                        name,
                                        type,
                                        placeholder,
                                        validators,
                                        error,
                                        errormsg,
                                        options,
                                        rows,
                                        value,
                                        maxLength,
                                        match,
                                        disable,
                                        checkbox_label,
                                    },
                                    index
                                ) => {
                                    const validateField = (e, match) => {
                                        let result = validateInput(validators, e.target.value);
                                        formAr[index].error = result.error;
                                        formAr[index].errormsg = result.message;
                                        setFormAr(formAr);
                                        if (match) {
                                            if (form[match] !== e.target.value) {
                                                formAr[index].error = true;
                                                formAr[index].errormsg = "Enter same password";
                                            }
                                        }
                                    };

                                    const onChangeEditorHandler = (value) => {
                                        setform((p) => ({ ...p, ["description"]: value }));
                                    };

                                    return (
                                        <Col sm={12} key={index} className="mb-3">
                                            <Form.Group controlId={name}>
                                                {label !== "" ? (
                                                    <Form.Label className="fw-bold text-dark">
                                                        {label}
                                                        {/* <span className="mandatorySign text-danger">*</span> */}
                                                    </Form.Label>
                                                ) : (
                                                    <></>
                                                )}
                                                {type === "text" ? (
                                                    <Form.Control
                                                        type={type}
                                                        placeholder={placeholder}
                                                        disabled={disable}
                                                        value={value}
                                                        name={name}
                                                        maxLength={maxLength}
                                                        onChange={(e) => {
                                                            onChangeHandler(e);
                                                            validateField(e);
                                                        }}
                                                    />
                                                ) : type === "password" ? (
                                                    <>
                                                        <Form.Control
                                                            type={showPassword ? "text" : "password"}
                                                            placeholder={placeholder}
                                                            disabled={disable}
                                                            value={value}
                                                            name={name}
                                                            onChange={(e) => {
                                                                onChangeHandler(e);
                                                                validateField(e, match);
                                                            }}
                                                        />
                                                        <div className="position-relative">
                                                            {showPassword ? (
                                                                <span
                                                                    className="eye"
                                                                    onClick={toggleShowPassword}
                                                                >
                                                                    <VisibilityIcon />
                                                                </span>
                                                            ) : (
                                                                <span
                                                                    className="eye"
                                                                    onClick={toggleShowPassword}
                                                                >
                                                                    <VisibilityOffIcon />
                                                                </span>
                                                            )}
                                                        </div>
                                                    </>
                                                ) : type === "email" ||
                                                    type === "number" ||
                                                    type === "file" ? (
                                                    <Form.Control
                                                        type={type}
                                                        placeholder={placeholder}
                                                        disabled={disable}
                                                        value={value}
                                                        name={name}
                                                        onChange={(e) => {
                                                            onChangeHandler(e);
                                                            validateField(e);
                                                        }}
                                                    />
                                                ) : type === "select" ? (
                                                    <Form.Control
                                                        as="select"
                                                        name={name}
                                                        value={value}
                                                        disabled={disable}
                                                        onChange={(e) => {
                                                            onChangeHandler(e);
                                                            validateField(e);
                                                        }}
                                                    >
                                                        <option value="">Select</option>
                                                        {options.map((item, index) => (
                                                            <option value={item.id} key={index}>
                                                                {item.label}
                                                            </option>
                                                        ))}
                                                    </Form.Control>
                                                ) : type === "textarea" ? (
                                                    <>
                                                        <WysiwygEditor
                                                            value={value}
                                                            onChange={onChangeEditorHandler}
                                                            changed={editorChanged}
                                                        />
                                                        <Form.Control
                                                            style={{ display: "none" }}
                                                            disabled
                                                            as="textarea"
                                                            name={name}
                                                            value={value}
                                                        />
                                                    </>
                                                ) : type === "checkbox" ? (
                                                    <Form.Check
                                                        type="checkbox"
                                                        name={name}
                                                        checked={value}
                                                        value={value}
                                                        label={checkbox_label}
                                                        onChange={(e) => onCheckboxHandler(e)}
                                                    />
                                                ) : (
                                                    <></>
                                                )}
                                                <small className="text-danger d-block">
                                                    &nbsp;{error ? errormsg : ""}
                                                </small>
                                            </Form.Group>
                                        </Col>
                                    );
                                }
                            )}
                        </Row>
                        {formlinks}

                        <div sm={12} md={12}>
                            <Col
                                className="btn1 text-center d-flex justify-content-center"
                                xs={12}
                                sm={12}
                                md={12}
                            >
                                {cancelBtn ? (
                                    <Button
                                        type="button"
                                        onClick={(e) => onCancelHandle(e)}
                                        className="fs-5 btn"
                                        variant="secondary"
                                    >
                                        Cancel
                                    </Button>
                                ) : (
                                    <></>
                                )}
                                <Button
                                    type="submit"
                                    className="text-center mx-5 my-3 loginSubmitBtn btn"
                                    variant=""
                                >
                                    {loading ? (
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    ) : (
                                        <></>
                                    )}
                                    {submitBtn}
                                </Button>
                            </Col>
                        </div>
                    </Form>
                </Card.Body>
            </Card>
        </FormWrap>
    );
};
