import Styled from "styled-components";

const TableWrap = Styled.div`
button {
    background-color:yellow;
}
.search-container {
    margin-bottom: 20px;
    margin-top: 20px;
    height: 40px;
    position: relative;
    text-align: right;
}
.common_table {
  padding: 0px 0px 0px 0px !important;
}
.common_header {
  padding: 0px;
  font: normal normal 600 30px/37px Montserrat;
  color: var(--orange-color);
}
.pagination {
    margin-top: 10px;
    padding-right: 0px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 20px;
    font: normal normal 600 20px Montserrat;
}
.previous {
    color: var(--orange-color);
    cursor: pointer;
}
.previous.disabled {
    color: #C2C2C2;
} 
.next {
    color: var(--orange-color);
    cursor: pointer;
}
.next.disabled {
    color: #C2C2C2;
}
.table-striped>tbody>tr:nth-of-type(odd)>* {
    color:${({ theme }) => theme["table_data-text"]};
    --bs-table-accent-bg: #FFEED8;
}
.table-bordered>:not(caption)>*>* {
    width:200px;
    border: 1px solid #CECECE;
}
table {
    padding-left:0px;
    margin-left:0px;
    border-collapse: collapse;
    thead {
        th {
            padding: 20px !important;
            font: normal normal medium 24px/29px Montserrat;
            text-align: center;
            background: var(--primary-color-light);
            vertical-align: middle;
        }
    }
    tbody {
        tr {
            td {
                text-align: center;
            }
        }
    }
}

@media (max-width: 991px) {
    table {
        tbody {
            tr {
                th {
                    white-space: nowrap;
                }
            }
        }
    }
}
`;

export default TableWrap;
