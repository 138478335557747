import React from 'react'
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap'
import UIModal from '../../Reuseable/RuModal'
import { TestCaseCreateWrap } from './style'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMutate } from '../../../Hooks/useMutate';
import moment from 'moment-timezone';

const schema = yup.object().shape({
    testCaseName: yup.string().required("Test Case Name is required"),
    apiUrl: yup.string().required("API URL is required"),
    sampleInput: yup.string().required("Sample Input is required"),
    expectedOutput: yup.string().required("Expected Output is required"),
    moduleName: yup.string().required("Module Name is required"),
    methodName: yup.string().required("Method Name is required"),
});

const TestCaseCreate = ({ show, onHide, project }) => {

    const onSuccess = () => {
        onHide()
    }


    const { mutate, isLoading } = useMutate("test-case-create", `/1-resource/${project?.ProjectCode}/createTestCase`, onSuccess, () => { })

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            testCaseName: "",
            apiUrl: "",
            sampleInput: JSON.stringify({
                userName: "test",
                password: "123456",
            }, null, 4),
            expectedOutput: JSON.stringify({
                userName: "test",
                password: "123456",
            }, null, 4),
            moduleName: "",
            methodName: ""
        }
    });

    const onSubmit = (data) => {
        const payLoad = {
            ProjectCode: project?.ProjectCode,
            TestCaseName: data.testCaseName,
            SampleInput: data.sampleInput,
            ExpectedOutput: data.expectedOutput,
            CreatedDate: moment().format("YYYY-MM-DD HH:mm:ss"),
            UpdatedDate: moment().format("YYYY-MM-DD HH:mm:ss"),
            ApiUrl: data.apiUrl,
            MethodName: data.methodName,
            ModuleName: data.moduleName
        }
        mutate(payLoad)
    }

    return (
        <UIModal show={show} onHide={onHide}>
            <TestCaseCreateWrap>
                <Container>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col md={12}>
                                <Form.Group controlId="testCaseName">
                                    <Form.Label>Test Case Name</Form.Label>
                                    <Form.Control {...register("testCaseName")} type="text" placeholder="Enter test case name" />
                                    {errors?.testCaseName?.message && <Form.Control.Feedback type="invalid">
                                        {errors.testCaseName?.message}
                                    </Form.Control.Feedback>}
                                </Form.Group>
                            </Col>
                            <Col md={12}>
                                <Form.Group controlId="apiUrl">
                                    <Form.Label>API URL</Form.Label>
                                    <Form.Control {...register("apiUrl")} type="text" placeholder="Enter API URL name" />
                                    {errors.apiUrl?.message && <Form.Control.Feedback type="invalid">
                                        {errors.apiUrl?.message}
                                    </Form.Control.Feedback>}
                                </Form.Group>
                            </Col>

                        </Row>
                        <Row>
                            <Col md={12}>
                                <Form.Group controlId="sampleInput">
                                    <Form.Label>Sample Input</Form.Label>
                                    <Form.Control {...register("sampleInput")} as="textarea" rows={4} placeholder="Enter sample input (JSON)" />
                                    {errors.sampleInput?.message && <Form.Control.Feedback type="invalid">
                                        {errors.sampleInput?.message}
                                    </Form.Control.Feedback>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Form.Group controlId="expectedOutput">
                                    <Form.Label>Expected Output</Form.Label>
                                    <Form.Control {...register("expectedOutput")} as="textarea" rows={4} placeholder="Enter expected output (JSON)" />
                                    {errors.expectedOutput?.message && <Form.Control.Feedback type="invalid">
                                        {errors.expectedOutput?.message}
                                    </Form.Control.Feedback>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="moduleName">
                                    <Form.Label>Module Name</Form.Label>
                                    <Form.Control {...register("moduleName")} type="text" placeholder="Enter module name" />
                                    {errors.moduleName?.message && <Form.Control.Feedback type="invalid">
                                        {errors.moduleName?.message}
                                    </Form.Control.Feedback>}
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="methodName">
                                    <Form.Label>Method Name</Form.Label>
                                    <Form.Control {...register("methodName")} type="text" placeholder="Enter method name" />
                                    {errors.methodName?.message && <Form.Control.Feedback type="invalid">
                                        {errors.methodName?.message}
                                    </Form.Control.Feedback>}
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Button type="submit" className='submit_button'>
                                {isLoading ? <Spinner animation='border' /> : "Submit"}
                            </Button>
                        </Row>

                    </Form>
                </Container>
            </TestCaseCreateWrap>
        </UIModal>
    )
}

export default TestCaseCreate