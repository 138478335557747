import Styled from 'styled-components';

const DashboardView = Styled.div`

.left_column {
   padding: .5rem;
   border-radius: 5px;
   margin-top: 5px;
   margin-bottom: 20px;
}
.right_column {
   padding: 1rem;
   border-radius: 5px;
   margin-bottom: 20px;
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}
.card {
   margin-bottom: .5rem;
}
.card-body {
   padding: 8px;
}
.add_new {
   display: flex;
   justify-content: start;
   align-items: center;
   font-size: 1.2rem;
   gap: .6rem;
   cursor: pointer;
}
.add_new_project {
   display: flex;
   justify-content: center;
   align-items: center;
}
.done_icon {
   margin-left: .3rem;
   color: green;
}
.close_icon  {
   color: var(--primary-color);
}
.delete_icon {
   color: var(--primary-color);
}
input[type="text"] {
   padding: 5px 10px;
   border: none;
   width: 100%;
}
.create_button {
   padding: 4px 8px;
}
.run_button {
   padding: 4px 8px;
}
.projects {
   margin-top: 1rem;
}
.project {
   display: flex;
   justify-content: space-between;
   align-items: center;
}
.card-body:has(.project_head) {
   background-color: #f5f5f5;
}
.project_head {
   border-right: 1px solid black;
}
.project_head:last-child {
   border-right: none;
}
.project_actions {
   display: flex;
   justify-content: center;
   align-items: center;
   gap: .5rem;
}
`;

export default DashboardView;
