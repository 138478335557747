import React from "react";
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "../Auth/protectedRoute";
import Layout from "../Components/Pages/Layout/Main"
import Login from "../Components/Pages/Login"
import PageNotFound from "../Components/Pages/PageNotFound"
import Dashboard from "../Components/Pages/Dashboard"
import TestCase from "../Components/Pages/TestCase";
import { useFetch } from "../Hooks/useFetch";

const UIRoutes = () => {

  const { data: projects, isLoading } = useFetch("projects", "2-resource/getAllProjects", () => { }, () => { }, false, true)

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="login" element={<Login />} />
      <Route element={<ProtectedRoute />}>
        <Route path="home" element={<Layout />}>
          <Route path="dashboard" element={<Dashboard projects={projects} isProjectsLoading={isLoading} />} />
          <Route path="test-case" element={<TestCase projects={projects} />} />
        </Route>
      </Route>

      <Route path="*" element={<PageNotFound />}></Route>
    </Routes>
  );
};

export default UIRoutes;

