import React, { useEffect } from "react";
import Tables from "../../Reuseable/RuTable";
import { Button, Container, Spinner } from "react-bootstrap";
import { useFetch } from "../../../Hooks/useFetch";
import { TestCaseWrap } from "./style";
import SelectBox from "../../Reuseable/RUSelectBox";
import { client } from "../../../App";

const columns = ['Test Case Id', 'Test Case Name', 'Module Name', 'Sample Input Data', 'Test Type', 'Expected Output']
const extra = null;
const header = "Test Cases"

const TestCase = ({ projects }) => {

  const pagination = { paginate: false, number: 0 }

  const ProjectOptions = projects?.data?.map((item) => ({ label: item?.ProjectName, value: item?.ProjectCode }))

  const [selectedProject, setSelectedProject] = React.useState(null)

  useEffect(() => {
    if (projects?.data?.length > 0) setSelectedProject({ label: projects?.data?.[0]?.ProjectName, value: projects?.data?.[0]?.ProjectCode })
  }, [projects])

  const { data: testCase, refetch, isLoading, isFetching } = useFetch(
    `test-case-${selectedProject === null ? ProjectOptions?.[0]?.value : selectedProject?.value}`,
    `/1-resource/${selectedProject === null ? ProjectOptions?.[0]?.value : selectedProject?.value}/allTestCase`,
    () => { }, () => { }, false, ProjectOptions?.length > 0 ?
    !client.getQueryData([`test-case-${selectedProject === null ? ProjectOptions?.[0]?.value : selectedProject?.value}`])
    : false)

  const tbody = <tbody>
    {testCase?.data?.map((row, index) => (
      <tr key={index}>
        <td>{row?.TestCaseID}</td>
        <td>{row?.TestCaseName}</td>
        <td>{row?.ModuleName}</td>
        <td>{row?.SampleInput}</td>
        <td>{row?.MethodName}</td>
        <td>{row?.ExpectedOutput}</td>
      </tr>
    ))}
  </tbody>

  const tableProps = { loading: isLoading, pagination, length: testCase?.data?.length, header, extra, columns, tbody, bordered: true }

  const handleSelectProject = (e) => {
    setSelectedProject(e)
  }

  return (

    <TestCaseWrap>
      <Container>
        <div className="pt-3">

          <div className="text_case_actions">

            <SelectBox options={ProjectOptions} value={selectedProject} onChange={handleSelectProject}
              placeholder="Select Project ..." className="project_select" />

            <Button
              className='refresh_button'
              onClick={refetch}
            >
              {isFetching ? <Spinner animation="border" variant="light" className="spinner" /> : "Refresh"}
            </Button>
          </div>

          <Tables props={(tableProps)} />

        </div>
      </Container>
    </TestCaseWrap>

  )
}

export default TestCase;    