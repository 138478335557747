import React, { useState } from "react";
import DashboardView from "./style";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import AddIcon from '@mui/icons-material/Add';
import { IconButton, TextField, useMediaQuery } from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { useMutate } from "../../../Hooks/useMutate";
import { client } from "../../../App";
import DeleteIcon from '@mui/icons-material/Delete';
import { useDelete } from "../../../Hooks/useDelete";
import UIModal from "../../Reuseable/RuModal";
import { usePatch } from "../../../Hooks/usePatch";
import TestCaseCreate from "../TestCaseCreate";
import Loading from "../../Reuseable/RuLoading";

const Dashboard = ({ projects, isProjectsLoading }) => {

  return (

    <DashboardView>

      {isProjectsLoading ? <div className="pt-5"><Loading /></div> : <Container fluid>
        <Row>
          <LeftColumn projects={projects} />
          <RightColumn projects={projects} />
        </Row>
      </Container>}

    </DashboardView>

  );
}

export default Dashboard

const ProjectCardHeader = ({ isSmallScreen }) => {

  if (isSmallScreen) return null

  return <Card>
    <Card.Body>
      <Row>
        <Col sm={3} className="project_head">
          <Card.Title>Project Id</Card.Title>
        </Col>
        <Col sm={3} className="project_head">
          <Card.Title>Project Name</Card.Title>
        </Col>
        <Col sm={3} className="project_head">
          <Card.Title>Number of Test Case</Card.Title>
        </Col>
        <Col sm={3} className="project_head">
          <Card.Title>Actions</Card.Title>
        </Col>
      </Row>
    </Card.Body>
  </Card>
}

const LeftColumn = ({ projects }) => {

  const isSmallScreen = useMediaQuery('(max-width:575px)')

  return <Col className="left_column" md={9}>
    <Container>
      <ProjectCardHeader isSmallScreen={isSmallScreen} />
      {projects?.data?.map((project, index) => <ProjectCardBody isSmallScreen={isSmallScreen} key={index} project={project} />)}
    </Container>
  </Col>
}


const ProjectCardBody = ({ project, isSmallScreen }) => {

  const [show, setShow] = useState(false);

  return <Card>
    <Card.Body>
      <Row>
        <Col sm={3} className={`project_col ${isSmallScreen ? "text-center" : ""}`}>
          <Card.Text><span>{isSmallScreen ? "Project Id: " : ""}</span> <span className={isSmallScreen ? "fw-bold" : "fw-normal"}>{project?.ProjectCode} </span></Card.Text>
        </Col>
        <Col sm={4} className={`project_col ${isSmallScreen ? "text-center" : ""}`}>
          <Card.Text><span>{isSmallScreen ? "Project Name: " : ""}</span> <span className={isSmallScreen ? "fw-bold" : "fw-normal"}>{project?.ProjectName} </span></Card.Text>
        </Col>
        <Col sm={1} className={`project_col pb-2 ${isSmallScreen ? "text-center" : ""}`}>
          <Card.Text> <span>{isSmallScreen ? "Number of Test Case: " : ""}</span> <span className={isSmallScreen ? "fw-bold" : "fw-normal"}>{project?.TestCaseCount} </span></Card.Text>
        </Col>
        <Col sm={4} className={`project_col ${isSmallScreen ? "text-center" : ""}`}>

          <div className="project_actions">
            <Button className="create_button" variant="secondary" onClick={() => setShow(true)}>
              Create
            </Button>

            <Button className="run_button">
              Run
            </Button>

            <TestCaseCreate project={project} show={show} onHide={() => setShow(false)} />
          </div>
        </Col>
      </Row>
    </Card.Body>


  </Card >
}

const RightColumn = ({ projects }) => {

  const [addProject, setAddProject] = useState(false)
  const [projectName, setProjectName] = useState("")

  const onSuccess = (data) => {
    if (!data.status) return
    setProjectName("")
    setAddProject(false)
    client.setQueryData(["projects"], { status: data.status, data: [...projects?.data, data?.data] })
  }

  const { mutate } = useMutate("addProject", "2-resource/addProject", onSuccess, () => { })

  const handleAddproject = () => {
    mutate({ ProjectName: projectName })
  }

  const handleProjectName = (e) => {
    setProjectName(e.target.value)
  }

  return <Col className="right_column" md={3}>
    <div className="add_new" onClick={() => setAddProject(true)}>
      <AddIcon /> Project
    </div>

    <div className="projects">

      {addProject && <Card>
        <Card.Body>
          <div className="add_new_project">

            <TextField onChange={handleProjectName} variant="standard" />

            <IconButton className="done_icon" onClick={handleAddproject}>
              <DoneIcon />
            </IconButton>

            <IconButton className="close_icon" onClick={() => setAddProject(false)}>
              <CloseIcon />
            </IconButton>

          </div>
        </Card.Body>
      </Card>}

      {projects?.data?.map((project, index) => <Project key={index} project={project} projects={projects} />)}
    </div>

  </Col>
}

const Project = ({ project, projects }) => {

  const [editProject, setEditProject] = useState(false)
  const [projectName, setProjectName] = useState("")
  const [confirmModal, setConfirmModal] = useState(false)

  const onEditSuccess = (data) => {
    if (!data.status) return
    setProjectName("")
    setEditProject(false)
    client.setQueryData(["projects"], {
      status: data.status, data: [...projects?.data].map((prj) => {
        if (prj?.ProjectCode === project?.ProjectCode) {
          return data?.data
        }
        return prj
      })
    })
  }

  const { mutate: editProjectName } = usePatch(`editProject-${project?.ProjectCode}`, `2-resource/${project?.ProjectCode}`, onEditSuccess, () => { })

  const onDeleteSuccess = (data) => {
    if (!data.status) return
    setConfirmModal(false)
    client.setQueryData(["projects"], { status: data.status, data: [...projects?.data].filter((prj) => prj.ProjectCode !== project.ProjectCode) })
  }

  const { mutate: deleteProject, isLoading: isDeleteLoading } = useDelete(`deleteProject`, "2-resource", onDeleteSuccess, () => { })

  const handleDeleteProject = () => {
    setConfirmModal(true)
  }

  const handleConfirmDeleteProject = () => {
    deleteProject(project?.ProjectCode)
  }

  const handleEditproject = () => {
    editProjectName({ ProjectName: projectName, TestCaseCount: project?.TestCaseCount, ProjectCode: project?.ProjectCode })
  }

  const handleProjectName = (e) => {
    setProjectName(e.target.value)
  }

  const handleClose = () => {
    setEditProject(false)
    setProjectName(project?.ProjectName)
  }

  const handleOpenEdit = () => {
    setEditProject(true)
    setProjectName(project?.ProjectName)
  }

  return <Card>
    <Card.Body>
      <div className="project">
        {editProject ? <div className="add_new_project">

          <TextField value={projectName} onChange={handleProjectName} variant="standard" />

          <IconButton className="done_icon" onClick={handleEditproject}>
            <DoneIcon />
          </IconButton>

          <IconButton className="close_icon" onClick={handleClose}>
            <CloseIcon />
          </IconButton>

        </div>
          :
          <div onClick={handleOpenEdit}>
            {project.ProjectName}
          </div>}

        {!editProject && <div className="project_actions">
          <IconButton className="delete_icon" onClick={handleDeleteProject}>
            <DeleteIcon />
          </IconButton>
        </div>}

      </div>

      <ConfirmModal project={project} show={confirmModal} isLoading={isDeleteLoading} onHide={() => setConfirmModal(false)}
        onConfirm={handleConfirmDeleteProject} />

    </Card.Body>
  </Card>
}

const ConfirmModal = ({ show, onHide, isLoading, project, onConfirm }) => {


  return (
    <UIModal show={show} onHide={onHide}>

      <div className="fs-5 mb-3">Are you sure you want to Delete <span className="fw-bold">{project?.ProjectName}</span></div>

      <div className="d-flex justify-content-center gap-3">

        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>

        <Button variant="primary" onClick={onConfirm} disabled={isLoading}>
          {isLoading ? 'Loading...' : 'Confirm'}
        </Button>
      </div>
    </UIModal>
  );
};