import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  :root {
    --primary-color: #f00;
    --primary-color-light: #ff9e9e;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  .btn-primary {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    &:hover, &:focus, &:active {
      background-color: var(--primary-color) !important;
      border-color: var(--primary-color) !important;
    }
    &:disabled {
      background: inherit !important;
      color: inherit;
      opacity: 0.5;
      border-color: var(--primary-color) !important;
    }
  }
`;
