import Styled from "styled-components";

const LoginWrap = Styled.div`        
        *,*::before,*::after {
            box-sizing: border-box;
        }
        .loginPageContent {
            height: 100vh;
            display: flex;
            justify-content: ${props => props.position ? props.position : "center"};
            align-items: center;
            background-image: linear-gradient(rgba(3, 3, 3, 0.5), rgba(10, 0, 0, 0.5)), url(${props => props.backgroundImage ? props.backgroundImage : "none"});
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            background-attachment: fixed;
        }
        .loginPageContent .card {
            background: ${props => props.backgroundColor ? props.backgroundColor : "white"};
            color: ${props => props.textColor ? props.textColor : "black"};
        }
        .container-width {
            box-shadow: 0px 0px 8px #0000004F;
            border-radius: 10px;
            opacity: 1;
            margin: 0px 40px;
            background: ${props => props.backgroundColor ? props.backgroundColor : "white"} !important;
        }
        label.form-label {
            color: ${props => props.textColor ? props.textColor : "white"} !important;
        }
        .signup p {
            color: ${props => props.textColor ? props.textColor : "white"} !important;
        }
        h5.login_heading {
            color: ${props => props.textColor ? props.textColor : "white"} !important;
        }
        .loginFormScreens {
            border-radius: 10px;
        }
        .logoimg {
            max-height: 70px;
        }
        .formScreenUser {
            color: #111247;
            font-weight: 600;
        }
        .formScreenTitle {
            color: #555;
            font-weight: 600;
        }

        .loginFormScreens {
            .form-control {
                padding: 10px 20px;
                font-size: 15px;
            }
        }
        form {
            label {
                color: ${({ theme }) => theme["gray-color"]};
            }
        }

        @media only screen and (min-width:1024px){
            .container-width {
                position: relative;
                display: flex;
                justify-content: left !important;
            }
        }
    
        @media only screen and (max-width:800px) {
            
            .container-width {
                display: flex;
                justify-content: center !important;
                width: 320px;
            }
            .loginPageContent {
                min-width: 350px;
                justify-content: center;
            }
        }

        @media only screen and (max-width:350px) {
            .loginPageContent {
                min-width: 320px !important;
            }
            .container-width {              
                width: 300px !important;        
            }
            .card {
                padding: 0px !important;
            }
        }
`;

export default LoginWrap;
