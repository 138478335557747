import styled from "styled-components";

const NotFoundWrap = styled.div`

background-image: url("/image/404bg.svg");
height: 100vh;
background-repeat: no-repeat;
background-size: cover;
background-position: center;
display: flex;
justify-content: center;
align-items: center;

img.image {
	object-fit: cover;
	width: 800px;
}

@media (max-width: 768px) {
	img.image {
		width: 100%;
	}
}

`;

export default NotFoundWrap;
