import React from "react"
import { useSelector } from "react-redux"
import LayoutWrap from "./style"
import { Outlet } from "react-router-dom"
import UIHeader from "../../../Reuseable/RuHeader"

const Main = () => {
	const layout = useSelector((state) => state.Auth.layout)

	return (
		<LayoutWrap>
			<div className="maincontainer-scroller">
				{layout.header.show && <UIHeader
				/>}
				<div className="container-fluid page-body-wrapper">


					{layout.main.show && <div className={"main-panel topmenu"}>
						<div className="content-wrapper">
							<Outlet
								context={{}}
							/>
						</div>
					</div>}
				</div>
			</div>
		</LayoutWrap>
	)
}

export default Main
