import { getContrastingColor } from "../Utilities/commonFn"
import AutoMationToolLogo from "../assets/images/icons/AutomationToolIcon.svg"
import LoginBgImg from "../assets/images/login/codeTesting-back.svg"

const loginBackground = "#FFFFFF" // should be RGB

export const masterDataSet = {
    login: {
        show: true,
        logo: {
            show: true,
            src: AutoMationToolLogo
        },
        signUp: {
            show: false,
            link: "/signup"
        },
        forgotPassword: {
            show: false,
            link: "/forgotPassword"
        },
        backgroundImage: LoginBgImg,
        loginTitle1: "",
        loginTitle2: "Testing Tool",
        loginFormPosition: "center", // start, end, center
        loginBackground: loginBackground,
        loginTextColor: getContrastingColor(loginBackground),
    },
    header: {
        show: true,
        background: "white", // "white" or "black"
        logo: {
            show: true,
        },
        menutoshow: 1, // upto 4 menu items can be shown on the header
        profile: false,
        notification: false,
        logout: true,
    },
    sidebar: {
        show: false,
        static: false,
    },
    main: {
        show: true,
    }
}